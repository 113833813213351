/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable import/order */
/* eslint-disable no-negated-condition */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { useConnectSnapStore } from '../hooks';
import { wait } from '../utils';
import {
  METAMASK_EXT_ID,
  METAMASK_FLASK_EXT_ID,
  METAMASK_FLASK_HOME,
} from '../env';

function Step2InstallMetamask() {
  const {
    teabag,
    metamask,
    setMetamaskLoading,
    setMetamaskWarning,
    setMetamaskCompleted,
  } = useConnectSnapStore();

  const checkMetamaskConnection = useCallback(async () => {
    setMetamaskLoading(true);
    await wait(300);
    try {
      const res = await getMetamaskStatus();
      if (res.installed && res.isFlask) {
        setMetamaskCompleted(true);
      } else if (!res.installed) {
        setMetamaskWarning('notInstalled');
      } else {
        setMetamaskWarning('wrongMetamask');
      }
    } catch (error: any) {
      setMetamaskWarning(error.message);
    }
    setMetamaskLoading(false);
  }, []);

  const message = useMemo(() => {
    if (metamask.completed) {
      return '';
    }

    if (metamask.warning === 'notInstalled') {
      return (
        <span>
          Click{' '}
          <a
            href={`https://chrome.google.com/webstore/detail/${METAMASK_FLASK_EXT_ID}`}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          to install MetaMask Flask
          <br />
          then click the button below.
          <br />
          <Button
            size="small"
            type="primary"
            onClick={() => window.location.reload()}
          >
            I've installed MetaMask Flask
          </Button>
        </span>
      );
    } else if (metamask.warning === 'wrongMetamask') {
      return (
        <span>
          You've installed the regular version of MetaMask but 0xTeabag requires{' '}
          <a href={METAMASK_FLASK_HOME} target="_blank" rel="noreferrer">
            MetaMask Flask
          </a>
          <ul>
            <li>
              Click{' '}
              <a
                href={`https://chrome.google.com/webstore/detail/${METAMASK_EXT_ID}`}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{' '}
              to uninstall MetaMask
            </li>
            <li>
              Click{' '}
              <a
                href={`https://chrome.google.com/webstore/detail/${METAMASK_FLASK_EXT_ID}`}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{' '}
              to install MetaMask Flask
            </li>
            <li>Click the button below</li>
          </ul>
          <Button
            size="small"
            type="primary"
            onClick={() => window.location.reload()}
          >
            I've installed MetaMask Flask
          </Button>
        </span>
      );
    } else if (metamask.warning) {
      return 'Something went wrong, please refresh the page and try again';
    }

    return '';
  }, [metamask.warning, metamask.completed]);

  useEffect(() => {
    if (teabag.completed) {
      checkMetamaskConnection();
    }
  }, [teabag.completed]);

  return <Container>{message}</Container>;
}

const Container = styled.div``;

async function getMetamaskStatus(): Promise<{
  installed: boolean;
  isFlask: boolean;
}> {
  const provider = window.ethereum;
  const status = {
    installed: false,
    isFlask: false,
  };

  try {
    const clientVersion = await provider?.request({
      method: 'web3_clientVersion',
    });
    const clientVersionNormalized = (
      (clientVersion as any) || ''
    ).toLowerCase();

    status.installed = clientVersionNormalized.includes('metamask');
    status.isFlask = clientVersionNormalized.includes('flask');
  } catch {
    // Do nothing
  }
  return status;
}

export default Step2InstallMetamask;
