/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable no-negated-condition */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { useConnectSnapStore } from '../hooks';
import { TEABAG_EXT_ID } from '../env';
import { wait } from '../utils';

function Step1InstallTeabag() {
  const { teabag, setTeabagLoading, setTeabagWarning, setTeabagCompleted } =
    useConnectSnapStore();

  const checkTeabagConnection = useCallback(async () => {
    setTeabagLoading(true);
    await wait(300);
    try {
      const res = await getTeabagInfo();
      if (res.installed && res.isAuth) {
        setTeabagCompleted(true);
      } else if (!res.installed) {
        setTeabagWarning('notInstalled');
      } else {
        setTeabagWarning('notAuth');
      }
    } catch (error: any) {
      setTeabagWarning(error.message);
    }
    setTeabagLoading(false);
  }, []);

  const message = useMemo(() => {
    if (teabag.completed) {
      return '';
    }

    if (teabag.warning === 'notInstalled') {
      return (
        <span>
          Click{' '}
          <a
            href={`https://chrome.google.com/webstore/detail/${TEABAG_EXT_ID}`}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          to install 0xTeabag
          <br />
          and sign in with your Google account
          <br />
          then click the button below.
          <br />
          <Button
            size="small"
            type="primary"
            onClick={() => window.location.reload()}
          >
            I've installed 0xTeabag
          </Button>
        </span>
      );
    } else if (teabag.warning === 'notAuth') {
      return (
        <span>
          Almost there!
          <br />
          Click{' '}
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              openTeabag();
            }}
          >
            here
          </a>{' '}
          to open 0xTeabag
          <br />
          and sign in with your Google account
          <br />
          then click the button below.
          <br />
          <Button size="small" type="primary" onClick={checkTeabagConnection}>
            I've signed in to 0xTeabag
          </Button>
        </span>
      );
    } else if (teabag.warning) {
      return 'Something went wrong, please refresh the page and try again';
    }

    return '';
  }, [teabag.warning, teabag.completed, checkTeabagConnection]);

  useEffect(() => {
    checkTeabagConnection();
  }, []);

  return <Container>{message}</Container>;
}

const Container = styled.div``;

async function getTeabagInfo(): Promise<{
  installed: boolean;
  isAuth: boolean;
}> {
  const res = { installed: false, isAuth: false };

  return new Promise((resolve) => {
    if (!(window as any).chrome.runtime) {
      console.error('Chrome runtime is not available');
      return resolve(res);
    }

    (window as any).chrome.runtime.sendMessage(
      TEABAG_EXT_ID,
      {
        __type: 'isAuth',
      },
      (response: boolean) => {
        if ((window as any).chrome.runtime.lastError) {
          return resolve(res);
        }
        res.installed = true;
        res.isAuth = response;
        return resolve(res);
      },
    );
  });
}

async function openTeabag(): Promise<void> {
  return new Promise((resolve) => {
    (window as any).chrome.runtime.sendMessage(
      TEABAG_EXT_ID,
      {
        __type: 'openOptionsPage',
      },
      () => {
        return resolve();
      },
    );
  });
}

export default Step1InstallTeabag;
