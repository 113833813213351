/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable import/order */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { useConnectSnapStore } from '../hooks';
import { versionToNumber, wait } from '../utils';
import { defaultSnapOrigin, isLocalSnap } from '../config';
import { GetSnapsResponse, Snap } from '../types';
import { SNAP_VERSION } from '../env';

function Step3InstallSnap() {
  const {
    metamask,
    snapInstall,
    setSnapInstallLoading,
    setSnapInstallCompleted,
  } = useConnectSnapStore();

  const [snapOutdated, setSnapOutdated] = useState(false);

  const checkSnapInstalled = useCallback(async () => {
    setSnapInstallLoading(true);
    await wait(300);
    try {
      const snap = await getSnap();
      if (snap) {
        console.log(
          snap,
          SNAP_VERSION,
          versionToNumber(snap.version),
          versionToNumber(SNAP_VERSION),
        );
        if (versionToNumber(snap.version) < versionToNumber(SNAP_VERSION)) {
          return setSnapOutdated(true);
        }
        setSnapOutdated(false);
        return setSnapInstallCompleted(true);
      }
      return setSnapInstallCompleted(false);
    } catch (error) {
      // do nothing
    } finally {
      setSnapInstallLoading(false);
    }
  }, []);

  const installSnap = useCallback(async () => {
    setSnapInstallLoading(true);
    try {
      await connectSnap({ params: { version: SNAP_VERSION } });
      await checkSnapInstalled();
    } finally {
      setSnapInstallLoading(false);
    }
  }, [checkSnapInstalled]);

  const message = useMemo(() => {
    if (!metamask.completed) {
      return '';
    }

    if (snapInstall.completed) {
      return isLocalSnap ? (
        <Button size="small" onClick={() => installSnap()}>
          Reinstall
        </Button>
      ) : (
        ''
      );
    }

    return (
      <>
        <div className="description">Click the button below:</div>
        <Button size="small" type="primary" onClick={() => installSnap()}>
          {snapOutdated ? 'Update' : 'Install'}
        </Button>
      </>
    );
  }, [snapInstall.completed, metamask.completed, snapOutdated]);

  useEffect(() => {
    if (metamask.completed) {
      checkSnapInstalled();
    }
  }, [metamask.completed]);

  return <Container>{message}</Container>;
}

const Container = styled.div`
  .description {
    margin-bottom: 8px;
  }
`;

export default Step3InstallSnap;

export const connectSnap = async ({
  snapId = defaultSnapOrigin,
  params = {},
}: {
  snapId?: string;
  params?: Record<'version' | string, unknown>;
} = {}) => {
  await window.ethereum.request({
    method: 'wallet_requestSnaps',
    params: {
      [snapId]: params,
    },
  });
};

export const getSnap = async (version?: string): Promise<Snap | undefined> => {
  try {
    const snaps = await getSnaps();

    return Object.values(snaps).find(
      (snap) =>
        snap.id === defaultSnapOrigin && (!version || snap.version === version),
    );
  } catch (error) {
    console.log('Failed to obtain installed snap', error);
    return undefined;
  }
};

export const getSnaps = async (): Promise<GetSnapsResponse> => {
  return (await window.ethereum.request({
    method: 'wallet_getSnaps',
  })) as unknown as GetSnapsResponse;
};
