/* eslint-disable no-restricted-globals */
/* eslint-disable no-implicit-coercion */
/* eslint-disable prefer-template */
/* eslint-disable padding-line-between-statements */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const NODE_ENV: 'development' | 'staging' | 'production' = env(
  process.env.GATSBY_NODE_ENV!,
  'string',
) as any;
export const IS_DEVELOPMENT = NODE_ENV === 'development';
export const IS_PRODUCTION = NODE_ENV === 'production';
export const SNAP_ORIGIN = env(process.env.GATSBY_SNAP_ORIGIN!, 'string');
export const TEABAG_HOME = env(process.env.GATSBY_TEABAG_HOME!, 'string');
export const TEABAG_EXT_ID = env(process.env.GATSBY_TEABAG_EXT_ID!, 'string');
export const METAMASK_HOME = env(process.env.GATSBY_METAMASK_HOME!, 'string');
export const METAMASK_EXT_ID = env(
  process.env.GATSBY_METAMASK_EXT_ID!,
  'string',
);
export const METAMASK_FLASK_HOME = env(
  process.env.GATSBY_METAMASK_FLASK_HOME!,
  'string',
);
export const METAMASK_FLASK_EXT_ID = env(
  process.env.GATSBY_METAMASK_FLASK_EXT_ID!,
  'string',
);
export const SNAP_VERSION = env(process.env.GATSBY_SNAP_VERSION!, 'string');

///////////////////////////////////////////////////////////////////////
// changed behavior because this project uses webpack.ProvidePlugin which needs literal process.env.VAR to work

type EnvReturnTypeStr = 'string' | 'number' | 'boolean';
type EnvReturnType = string | number | boolean;

function env(envVar: string, type: 'string', defaultVal?: string): string;
function env(envVar: string, type: 'number', defaultVal?: number): number;
function env(envVar: string, type: 'boolean', defaultVal?: boolean): boolean;
function env(
  envVar: string,
  type: EnvReturnTypeStr,
  defaultVal?: EnvReturnType,
): EnvReturnType {
  let val: EnvReturnType = envVar as any;
  val = validateRequiredOrApplyDefault(envVar, val, defaultVal as any);
  return convertType(envVar, val, type);
}

function validateRequiredOrApplyDefault(
  key: string,
  val: EnvReturnType,
  defaultVal: EnvReturnType,
): EnvReturnType {
  if (defaultVal === undefined && !val) {
    throw new Error(`Missing required env var ${key}`);
  }

  return val || defaultVal;
}

function convertType(
  key: string,
  val: EnvReturnType,
  type: EnvReturnTypeStr,
): EnvReturnType {
  switch (type) {
    case 'number': {
      return convertToNumber(key, val);
    }
    case 'boolean': {
      return convertToBoolean(key, val);
    }
    default: {
      return val;
    }
  }
}

function convertToNumber(key: string, val: EnvReturnType): number {
  const numb = Number(val);

  if (isNaN(numb)) {
    throw new Error(`Env var ${key} is not a number`);
  }

  return numb;
}

function convertToBoolean(key: string, val: EnvReturnType): boolean {
  const valLower = String(val).toLowerCase();
  const truthyVals = ['true', '1'];
  const falsyVals = ['false', '0'];
  const acceptedVals = [...truthyVals, ...falsyVals];

  if (!acceptedVals.includes(valLower)) {
    throw new Error(`Env var ${key} is not a boolean`);
  }

  return truthyVals.includes(valLower);
}
