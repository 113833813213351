/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsdoc/require-jsdoc */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useMemo } from 'react';
import { StepProps, Steps } from 'antd';
import { ApiOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { ReactComponent as TeabagIcon } from '../assets/teabag_icon.svg';
import { ReactComponent as FlaskIcon } from '../assets/flask_icon.svg';
import { ReactComponent as SnapIcon } from '../assets/snap_icon.svg';
import { gray, teal, white } from '../config/colors';
import { useConnectSnapStore } from '../hooks';
import Step1InstallTeabag from './Step1InstallTeabag';
import Step2InstallMetamask from './Step2InstallMetamask';
import Step3InstallSnap from './Step3InstallSnap';
import Step4AuthSnap from './Step4AuthSnap';

function ConnectSnapSteps() {
  const { step, teabag, metamask, snapInstall, snapAuth } =
    useConnectSnapStore();

  const steps: StepProps[] = useMemo(() => {
    const items: StepProps[] = [];

    let teabagIcon: React.ReactNode = (
      <TeabagIcon className="customIcon teabag" />
    );

    if (teabag.loading) {
      teabagIcon = <LoadingOutlined />;
    } else if (teabag.completed) {
      teabagIcon = undefined;
    }

    items.push({
      title: 'Install 0xTeabag',
      description: <Step1InstallTeabag />,
      icon: teabagIcon,
    });

    let metamaskIcon: React.ReactNode = (
      <FlaskIcon className="customIcon flask" />
    );

    if (metamask.loading) {
      metamaskIcon = <LoadingOutlined />;
    } else if (metamask.completed) {
      metamaskIcon = undefined;
    }

    items.push({
      title: 'Install MetaMask Flask',
      description: <Step2InstallMetamask />,
      icon: metamaskIcon,
    });

    let snapInstallIcon: React.ReactNode = (
      <SnapIcon className="customIcon snap" />
    );

    if (snapInstall.loading) {
      snapInstallIcon = <LoadingOutlined />;
    } else if (snapInstall.completed) {
      snapInstallIcon = undefined;
    }

    items.push({
      title: 'Install 0xTeabag Snap',
      description: <Step3InstallSnap />,
      icon: snapInstallIcon,
    });

    let snapAuthIcon: React.ReactNode = (
      <ApiOutlined className="customIcon connect" />
    );

    if (snapAuth.loading) {
      snapAuthIcon = <LoadingOutlined />;
    } else if (snapAuth.completed) {
      snapAuthIcon = undefined;
    }

    items.push({
      title: 'Connect Snap to 0xTeabag',
      description: <Step4AuthSnap />,
      icon: snapAuthIcon,
    });

    let doneDescription: React.ReactNode = '';

    if (snapAuth.completed) {
      doneDescription = (
        <span>
          You're all set! 🚀
          <br />
          <br />
          <span className="close">You can close this page now.</span>
        </span>
      );
    }

    items.push({
      title: 'Done',
      description: doneDescription,
      icon: <SmileOutlined className="customIcon done" />,
    });

    return items;
  }, [step, teabag, metamask, snapInstall, snapAuth]);

  return (
    <Container>
      <Steps direction="vertical" current={step} items={steps} />
    </Container>
  );
}

const Container = styled.div`
  width: 300px;

  .ant-steps
    .ant-steps-item-active
    .ant-steps-item-title.ant-steps-item-title.ant-steps-item-title {
    color: ${white};
  }

  .ant-steps-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    .ant-steps-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-steps-item-wait {
    opacity: 0.5;
    .ant-steps-item-icon {
      filter: grayscale(100%);
    }
  }

  .ant-steps-item-tail:after {
    background-color: ${gray} !important;
  }

  .customIcon {
    width: 24px;

    &.connect svg {
      color: ${teal};
    }
    &.done svg {
      color: ${teal};
    }
  }

  ul {
    margin: 0;
  }

  .close {
    font-style: italic;
  }
`;

export default ConnectSnapSteps;
